<template>
    <page>
        <page-head>
            <app-button property="success" icon="icon-add" @click="createModal">
                {{ translate('button.Add') }}
            </app-button>
        </page-head>

        <page-body>
            <data-grid
                :data-source="incomeitems"
                :columns="columns"
                :action-column-width="120"
                :action-column-text="translate(translateKey + '.Label.Action')"
            >
                <div slot="actionSlot" slot-scope="list">
                    <app-button
                        v-if="can(permission + '.update')"
                        @click="createModal(list.row.form)"
                        :sm="true"
                        :title="translate('button.Edit')"
                        icon="icon-edit-2"
                        property="primary"
                    />

                    <!-- <app-button
                        v-if="can(permission + '.action')"
                        :sm="true"
                        @click="action(list.row, 'active')"
                        :title="list.row.action.active ? translate('button.DeActivate') : translate('button.Activate')"
                        :icon="!list.row.action.active ? 'icon-eye-off' : 'icon-eye'"
                        property="success"
                    /> -->

                    <app-button
                        v-if="can(permission + '.delete')"
                        :sm="true"
                        @click="remove(list.row.id)"
                        :title="translate('button.Delete')"
                        icon="icon-trash-2"
                        property="danger"
                    />

                </div>
            </data-grid>
        </page-body>

        <modal :id="modalId" size="md">
            <modal-head>
                <modal-title>{{ currentPage.title }}</modal-title>
            </modal-head>
            <modal-body v-if="modelShow">
                <form @submit.prevent="save">
                    <grid>
                        <div>
                            <form-group
                                :label="'Category'"
                                name="category"
                            >
                                <form-tree-select :options="categories" displayExpr="title" v-model="form.category"/>
                            </form-group>
                        </div>

                        <form-group  
                            :label="'Qiymət'"
                            name="price"
                        >
                            <form-number v-model="form.price"/>
                        </form-group>
                        
                        <form-group
                            :label="'Nağd'"
                            name="cash"
                        >
                            <form-number v-model="form.cash"/>
                        </form-group>
                        
                        <form-group
                            :label="'Nağdsız'"
                            name="cashless"
                        >
                            <form-text v-model="form.cashless"/>
                        </form-group>

                        <form-group
                            :label="'Qeyd'"
                            :name="'note'"
                            :key="'note'"
                        >
                            <form-text-area type="simple" v-model="form.note"/>
                        </form-group>

                        <app-button class="justify-center" property="success" type="submit">
                            {{ translate('button.Save') }}
                        </app-button>
                    </grid>
                </form>
            </modal-body>
        </modal>
    </page>
</template>

<script>
/*
 * Import Components
 * */
import {mapActions, mapState} from 'vuex';

const modalId = 'createModal';
const translateKey = 'crm.Costs';

export default {
    name: "OtherIncomeIndex",
    data() {
        return {
            translateKey,
            modalId,
            modelShow: false,
            photoItems: [],
            columns: [
                {
                    caption: 'Kategorya',
                    dataField: 'category',
                    show: true
                },
                {
                    caption: 'Qiymət',
                    dataField: 'price',
                    show: true
                },
                {
                    caption: 'Nağd',
                    dataField: 'cash',
                    show: true
                },
                {
                    caption: 'Nağdsız',
                    dataField: 'cashless',
                    show: true
                },
                {
                    caption: 'Tarix',
                    dataField: 'created_at',
                    show: true,
                    search: false
                },
            ],
            form: {}
        }
    },
    computed: {
        ...mapState('OtherIncomeStore', ['incomeitems']),
        ...mapState('OtherIncomeCategoriesStore', ['categories']),
        permission() {
            return this.currentPage.permission;
        },
        
    },
    methods: {
        ...mapActions('OtherIncomeStore', ['getIncomes', 'setIncome', 'actionIncome', 'deleteIncome']),
        ...mapActions('OtherIncomeCategoriesStore', ['getSelectIncomeCategories']),
        /*
         * Form Create
         * */
        formCreate(item = {}) {
            const form = {
                id: item.id || null,
                // type: item.type || null,
                price: item.price || null,
                note: item.note || null,
                cash: item.cash || null,
                cashless: item.cashless || null,
                category: item.category || null,
            }
            this.form = form;
            this.modelShow = true;
        },
        /*
         * Create Modal
         * */
        createModal(item = {}) {
            this.modal(this.modalId)
            this.modelShow = false;
            this.resetError();
            this.formCreate(item);
        },
        /*
         * Remove
         * */
        remove(id) {
            this.alert().then(r => this.deleteIncome(id).then(r => this.getIncomes()))
        },
        /*
         * Action
         * */
        action(item, type) {
            let action = item.action[type] ? 0 : 1;
            this.actionIncome({id: item.id, type, action}).then(r => this.getIncomes())
        },
        
        /*
         * Save
         * */
        save() {
            this.setIncome(this.form)
            .then(r => {
                if (r) {
                    this.modal(this.modalId);
                    this.getIncomes();
                }
            })
        }
    },
    created() {
        this.getIncomes();
        this.getSelectIncomeCategories();
        console.log(this.IncomeItems);
    }
}
</script>

<style scoped>

</style>
